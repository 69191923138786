<template>
    <div class="fl_c al_c mar_bottom" :style="{'background-color': ($common.mobile()&&isweixin ? '#f0f0f0':'')}">
        <div class="fl_r al_c ju_c  contain" v-if="!$common.mobile()">
            <div class="content">
                <div class="pad_around">
                    <div class="fl_c al_c">
                        <div class="contentTitle pad_around">
                            下载中心
                        </div>
                        <div class="fl_r">
                            <div v-for="(item,index) in downloadList" :key="index" class="list fl_r ju_b pad_top"
                                 style="cursor: pointer" @click="downloadNow(item.file1,item.downId)">
                                <div class="fl_c al_c ">
                                    <img v-if="item.fileDesc1.substr(0,3)=='安卓端'" class="imgSty"
                                         src="@/assets/img/android.png" alt="">
                                    <img v-else-if="item.fileDesc1.substr(0,3)=='苹果端'" class="imgSty"
                                         src="@/assets/img/ios.png" alt="">
                                    <img v-else-if="item.fileDesc1.substr(0,3)=='电脑端'" class="imgSty"
                                         src="@/assets/img/windows.png" alt="">
                                    <div class="fileName">{{item.fileDesc1}}</div>
                                    <div class="visionName">版本号：{{item.versionName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fl_c ju_b contain" style="width: 100%;margin-top: 50px" v-if="$common.mobile()&&!showPic">
            <div class="secondTabBox">
                <div class="topTitle">
                    下载中心
                </div>
            </div>
            <div class="content" style="width: 100%;height: auto;margin-top: 34px">
                <div class="pad_around">
                    <div class="fl_c al_c ju_c">
                        <!--                        <div class="contentTitle pad_leven">-->
                        <!--                            下载中心-->
                        <!--                        </div>-->
                        <div class="fl_r fl_w" v-if="!isweixin">
                            <div v-for="(item,index) in downloadList" :key="index" class="list fl_r ju_b pad_top ju_c"
                                 style="cursor: pointer;width: 50%;" @click="downloadNow(item.file1,item.downId)">
                                <div class="fl_c al_c">
                                    <img v-if="item.fileDesc1.substr(0,3)=='安卓端'" class="imgSty1"
                                         src="@/assets/img/android.png" alt="">
                                    <img v-else-if="item.fileDesc1.substr(0,3)=='苹果端'" class="imgSty1"
                                         src="@/assets/img/ios.png" alt="">
                                    <img v-else-if="item.fileDesc1.substr(0,3)=='电脑端'" class="imgSty1"
                                         src="@/assets/img/windows.png" alt="">
                                    <div class="fileName1">{{item.fileDesc1}}</div>
                                    <div class="visionName1">版本号：{{item.versionName}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="fl_r fl_w" v-else>
                            <div v-for="(item,index) in downloadList" :key="index" class="list fl_r ju_b pad_top ju_c"
                                 style="cursor: pointer;width: 50%;" @click="showPicFun">
                                <div class="fl_c al_c">
                                    <img v-if="item.fileDesc1.substr(0,3)=='安卓端'" class="imgSty1"
                                         src="@/assets/img/android.png" alt="">
                                    <img v-else-if="item.fileDesc1.substr(0,3)=='苹果端'" class="imgSty1"
                                         src="@/assets/img/ios.png" alt="">
                                    <img v-else-if="item.fileDesc1.substr(0,3)=='电脑端'" class="imgSty1"
                                         src="@/assets/img/windows.png" alt="">
                                    <div class="fileName1">{{item.fileDesc1}}</div>
                                    <div class="visionName1">版本号：{{item.versionName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sliderSty" style="width: 100%;height: auto;">
                <side-bar/>
            </div>
        </div>
        <div class="fl_c ju_b contain" style="width: 100%;position: relative;background-color: #f0f0f0;height: 1000px"
             v-if="$common.mobile()&&showPic">
            <img src="@/assets/img/tips.jpg" alt="">
        </div>
    </div>
</template>

<script>
    import {getDownload} from "../../utils/api";

    export default {
        name: "downloadPage",
        components: {},
        data() {
            return {
                downloadList: [],
                isweixin: false,
                showPic: false,
            };
        },
        mounted() {
            window.scrollTo(0, 0);
            this.getDownload();
            if (this.isWeixinBrowser()) {
                this.isweixin = true;
                this.$store.commit("setWeixing", true);
            } else {
                this.$store.commit("setWeixing", false);
            }
        },
        methods: {
            showPicFun() {
                this.showPic = true
            },
            isWeixinBrowser() {
                let ua = navigator.userAgent.toLowerCase();
                return /micromessenger/.test(ua) ? true : false;
            },
            async getDownload() {
                let res = await getDownload();
                this.downloadList = res.data;
            },
            downloadNow(url, downId) {
                    window.open(url);
            },
        },
    };
</script>

<style scoped lang="scss">
    .sliderSty {
        width: 260px;
        height: 100px;
    }

    .secondTabBox {
        width: 100%;
        height: auto;
        border: 1px solid #dddddd;
        margin-bottom: 10px;
        position: fixed;
    }

    .topTitle {
        width: 100%;
        height: 34px;
        background-color: #195b40;
        font: 600 16px/34px "宋体";
        color: #fff;
        text-align: center;
    }

    .contain {
        width: 1200px;
        margin-top: 10px;
        height: auto;

        .content {
            width: 930px;
            border: 1px solid #ccc;
            min-height: 800px;

            .contentTitle {
                font-size: 20px;
                font-weight: bold;
            }

            .contentTime {
                width: 100%;
                line-height: 30px;
                height: 30px;
                text-align: center;
                background: #f5f5f5;
                color: #999;
                margin: 20px 0;
            }

            .contentImg {
                width: 100%;
                height: 100%;
            }

            .contentSty {
                margin-top: 20px;
            }
        }
    }

    .visionName {
        width: auto;
        font-size: 10px;
        text-align: center;
        color: #666666;
        line-height: 21px;
    }

    .fileName {
        width: 225px;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;
        padding: 10px 0;
    }

    .visionName1 {
        width: auto;
        font-size: 10px;
        text-align: center;
        color: #666666;
        line-height: 21px;
    }

    .fileName1 {
        width: auto;

        font-size: 13px;
        text-align: center;

        padding: 10px 0;
    }

    .downType {
        width: 70px;
        font-weight: 800;
    }

    .imgSty {
        width: 100px;
        height: 100px;
    }

    .imgSty1 {
        width: 60px;
        height: 60px;
    }
</style>
